/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import React, { memo, useContext, useState, useEffect, useMemo, useCallback } from "react"
import { OrderDetailContext } from "../useOrderDetail"
import { Badge, Button, Card, Space, Table, message, Row, Col, Dropdown, Menu } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { ColumnsType } from "antd/lib/table"
import { InvoiceOfflineForm } from "../../../../../../components/FormTemplate/InvoiceOffline"
import {
  checkValueToContinue,
  confirmToContinue,
  formatAmount,
} from "../../../../../../utils/utils"
import { INVOICE_TYPE_MAP, InvoiceItemStatusEnum } from "../../../../../../constant/invoice"
import {
  postCancelInvoice,
  postDeleteInvoice,
  uploadOfflineInvoice,
  getInvoicesList,
  inVoiceReverse,
  getTradingPreviewInvoice,
} from "../../../../promises"
import ModalForm from "../../../../../../components/ModalForm"
import ProcureApplyBilling from "../../components/ApplyBilling"
import { cloneDeep, subtract } from "lodash"
import { useParams } from "react-router-dom"
import moment from "moment"
import { accuracySubtract } from "../../../../../../utils/formatNumber"

export const OrderInvoiceInfo: React.FC<{}> = memo(() => {
  const historyId = useParams()?.id
  const context: any = useContext(OrderDetailContext)
  const [isModalOpenApply, setIsModalOpenApply] = useState<Boolean>(false) //申请开票弹框
  const currentTenantId = sessionStorage.getItem("tenantId")
  const [hidden, setHidden] = useState<any>(true)

  console.log(context, "context")
  const columns: ColumnsType<API.Invoice.Item> = [
    { title: "开票日期", dataIndex: "invoiceDate" },
    { title: "发票号", dataIndex: "invoiceNo" },
    { title: "发票项目名称", dataIndex: "itemProjectName" },
    {
      title: "规格",
      dataIndex: "itemSpecification",
      render: (text) => (!text || text == "无规格" ? "-" : text),
    },
    { title: "订单关联数量", dataIndex: "itemQuantity" },
    {
      title: "订单关联价税合计",
      dataIndex: "orders",
      render: (item, records) => {
        const amount = item
          ?.filter((t: any) => t.orderId == historyId)
          .reduce((pre: any, cut: any) => pre + Number(cut.amountWithTax), 0)
        return records?.invoiceColor == "RED" ? `-${formatAmount(amount)}` : formatAmount(amount)
      },
    },
    {
      title: "订单关联增值税",
      dataIndex: "orders",
      render: (item, records) => {
        const amount = item
          ?.filter((t: any) => t.orderId == historyId)
          .reduce((pre: any, cut: any) => pre + Number(cut.taxAmount), 0)
        return records?.invoiceColor == "RED" ? `-${formatAmount(amount)}` : formatAmount(amount)
      },
    },
    {
      title: "开票状态",
      dataIndex: "invoiceStatus",
      render: (type: string) => <Badge {...InvoiceItemStatusEnum[type]}></Badge>,
    },
    { title: "票种", dataIndex: "invoiceType", render: (type: string) => INVOICE_TYPE_MAP[type] },
    {
      title: "操作",
      fixed: "right",
      align: "center",
      dataIndex: "actions",
      render: (_, records) => {
        let _drowItem = [
          {
            key: "3",
            label: (
              <ModalForm<API.Invoice.ManualInvoiceUpload>
                fieldProps={{
                  labelCol: { span: 5 },
                  wrapperCol: { span: 17 },
                }}
                modalProps={{ width: 640, destroyOnClose: true }}
                title="上传冲红发票"
                trigger={<a>上传发票</a>}
                onFinish={async (values) => {
                  const params: any = cloneDeep(values)
                  params.taxRate = params?.taxRate ? params?.taxRate / 100 : 0
                  params.price = params?.unitPrice
                  params.invoiceId = records?.id
                  params.invoiceDate = moment(params?.invoiceDate).format("YYYY-MM-DD")
                  await uploadOfflineInvoice({ ...params, orderId: context?.data.id })
                  console.log("成功")
                  await context?.refresh()
                  init()
                  message.success("操作成功")
                  return Promise.resolve()
                }}
              >
                <InvoiceOfflineForm
                  formProps={{ inVoiceColor: "red", records }}
                  config={{
                    quantity: {
                      rules: [
                        {
                          validator: (_, value) => {
                            const res = checkRed(value, records, "itemQuantity", "数量")
                            if (!res.flag) {
                              return Promise.reject(res.message)
                            }
                            return Promise.resolve()
                          },
                        },
                      ],
                    },
                    taxAmount: {
                      rules: [
                        {
                          validator: (_, value) => {
                            const res = checkRed(value, records, "taxAmount", "增值税")
                            if (!res.flag) {
                              return Promise.reject(res.message)
                            }
                            return Promise.resolve()
                          },
                        },
                      ],
                    },
                    totalAmount: {
                      rules: [
                        {
                          validator: (_, value) => {
                            const res = checkRed(value, records, "amountWithTax", "价税合计")
                            if (!res.flag) {
                              return Promise.reject(res.message)
                            }
                            return Promise.resolve()
                          },
                        },
                      ],
                    },
                    amount: {
                      rules: [
                        {
                          validator: (_, value) => {
                            const res = checkRed(value, records, "amountWithoutTax", "金额")
                            if (!res.flag) {
                              return Promise.reject(res.message)
                            }
                            return Promise.resolve()
                          },
                        },
                      ],
                    },
                  }}
                ></InvoiceOfflineForm>
              </ModalForm>
            ),
          },
          {
            key: "4",
            label: (
              <span
                onClick={async () => {
                  const res: any = await inVoiceReverse({ invoiceId: records?.id })
                  if (res.code == 200) {
                    message.success("操作成功")
                    init()
                    await context.refresh()
                  }
                }}
              >
                在线冲红
              </span>
            ),
          },
        ]
        if (
          records?.invoiceColor == "BLUE" &&
          !records?.issueChannel &&
          records?.invoiceStatus == "ISSUED"
        ) {
          // 符合
        } else {
          _drowItem.pop()
        }
        return (
          <Space>
            {records?.invoiceStatus == "PENDING" && (
              <a
                onClick={async () => {
                  await confirmToContinue({
                    title: "撤销开票",
                    content: (
                      <div>
                        {records.isMerge ? (
                          <>
                            <div>
                              确定撤销吗？当前申请为合并订单开票，撤销开票申请将同步撤销以下订单的开票申请
                            </div>
                            <div
                              style={{
                                background: "#efefef",
                                margin: "12px 0",
                                padding: "8px 4px",
                              }}
                            >
                              {records?.orders?.map((item: any) => item?.orderId).join(",")}
                            </div>
                          </>
                        ) : (
                          <>确定撤销吗?</>
                        )}
                      </div>
                    ),
                  })
                  const res: any = await postCancelInvoice({ invoiceId: records.id })
                  if (res.code == 200) {
                    message.success("操作成功")
                    init()
                    await context.refresh()
                  }
                }}
              >
                撤销
              </a>
            )}
            {["ISSUED", "REVERSED", "REVERSING"].includes(records?.invoiceStatus) && (
              <a
                onClick={async () => {
                  const res = await getTradingPreviewInvoice(records?.taskId)
                  if (res.code == 200) {
                    let a: any = document.createElement("a")
                    a.href = res.data
                    a.click()
                  }
                }}
              >
                查看发票
              </a>
            )}
            {records?.invoiceStatus == "ISSUED" && records?.issueChannel == "MANUAL" && (
              <a
                onClick={async () => {
                  await confirmToContinue({ title: "提示", content: <>是否删除发票</> })
                  await checkValueToContinue(records.id)
                  const res: any = await postDeleteInvoice({ invoiceId: records.id! })
                  if (res.code == 200) {
                    message.success("操作成功")
                    init()
                    await context.refresh()
                  }
                }}
              >
                删除
              </a>
            )}
            {records?.invoiceColor == "BLUE" && records?.invoiceStatus == "ISSUED" && (
              <Dropdown
                overlay={<Menu items={_drowItem} />}
                placement="bottom"
                trigger={["click"]}
                arrow={true}
              >
                <a onClick={(e: any) => e.preventDefault()}>
                  冲红
                  <DownOutlined />
                </a>
              </Dropdown>
            )}
          </Space>
        )
      },
    },
  ]
  const hiddenHan = () => {
    let hidden = true
    if (
      context.data.drawer == "ALL" ||
      (context?.data.drawer === "BUYER" && currentTenantId === context?.data.buyerId) ||
      (context?.data.drawer === "SELLER" && currentTenantId === context?.data.sellerId)
    ) {
      hidden = false
    } else {
      hidden = true
    }
    setHidden(hidden)
  }
  const [dataSource, setDataSource] = useState<any>([])
  const init = async () => {
    const res = await getInvoicesList({ invoiceId: historyId })
    if (res.code == 200) {
      setDataSource(res.data)
    }
  }
  // 蓝票提交校验
  const checkBlue = (type: string, text: string, value: any) => {
    const result = {
      flag: true,
      message: "",
    }
    // 总数量
    const rootData = context?.data?.merchandises
    const _data = dataSource.map((item: any) => {
      return {
        quantity: item.itemQuantity, // 数量
        totalPrice: item.amountWithoutTax, // 金额
        totalAmount: item.amountWithTax, // 价税金额
        vatAmount: item.taxAmount, // 增值税
      }
    })
    // 当前开票总量
    const currentAllData: any = {
      quantity:
        _data.reduce((a: number, b: { quantity: number }) => a + Math.round(b.quantity * 100), 0) /
        100,
      totalPrice:
        _data.reduce(
          (a: number, b: { totalPrice: number }) => a + Math.round(b.totalPrice * 100),
          0
        ) / 100,
      totalAmount:
        _data.reduce(
          (a: number, b: { totalAmount: number }) => a + Math.round(b.totalAmount * 100),
          0
        ) / 100,
      vatAmount:
        _data.reduce(
          (a: number, b: { vatAmount: number }) => a + Math.round(b.vatAmount * 100),
          0
        ) / 100,
    }
    if (rootData?.length) {
      let difference = accuracySubtract(rootData[0][type], currentAllData[type])
      if (Number(difference) < Number(value)) {
        result.flag = false
        result.message = `不可超过剩余未开票${text}:${difference}`
      }
    }
    return result
  }
  // 红票提交校验
  const checkRed = (value: any, records: any, type: string, text: string) => {
    const result = {
      flag: false,
      message: `红票${text}为负数,且绝对值与对应蓝票相等。蓝票${text}: ${records[type]}`,
    }
    if (value <= 0 && Math.abs(value) == records[type]) {
      result.flag = true
      result.message = ""
    }
    return result
  }
  // 获取可申请开票金额
  const getAllowAmount = useMemo(() => {
    const rootData = context?.data?.merchandises
    if (!rootData?.length) return 0
    const currentAmount = dataSource
      .map((item: any) => {
        return {
          totalPrice: item.amountWithoutTax, // 金额
        }
      })
      ?.reduce((a: any, b: any) => a + b.totalPrice, 0)
    return rootData[0]?.totalPrice - currentAmount
  }, [JSON.stringify(dataSource), context?.data?.merchandises])
  // 蓝票上传开关
  const blueFlag = useMemo(() => {
    return subtract(context?.data?.afterVatAmt, context?.data?.invoiceTotalAmountWithPending)
  }, [context?.data?.afterVatAmt, context?.data?.invoiceTotalAmountWithPending])

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    hiddenHan()
  }, [context.data])
  return (
    <Card
      title="开票信息"
      extra={
        <Space>
          {/* {getAllowAmount() > 0 ? (
            <Button type="primary" disabled={hidden} onClick={() => setIsModalOpenApply(true)}>
              申请开票
            </Button>
          ) : null} */}
          <ModalForm<API.Invoice.ManualInvoiceUpload>
            fieldProps={{
              labelCol: { span: 5 },
              wrapperCol: { span: 17 },
            }}
            modalProps={{ width: 640, destroyOnClose: true }}
            title="上传蓝票"
            trigger={
              <Button
                hidden={blueFlag <= 0}
                disabled={context.data.invoiceStatus == "REVIEWED" ? true : false}
                type="primary"
              >
                上传蓝票
              </Button>
            }
            onFinish={async (values) => {
              const params: any = cloneDeep(values)
              params.taxRate = params?.taxRate ? params?.taxRate / 100 : 0
              params.price = params?.unitPrice
              params.invoiceDate = moment(params?.invoiceDate).format("YYYY-MM-DD")
              const res: any = await uploadOfflineInvoice({ ...params, orderNo: context?.data.id })
              await context?.refresh()
              init()
              message.success("操作成功")
              return Promise.resolve()
            }}
          >
            <InvoiceOfflineForm
              formProps={{ inVoiceColor: "blue" }}
              config={{
                quantity: {
                  rules: [
                    {
                      validator: (_, value) => {
                        const res = checkBlue("quantity", "数量", value)
                        if (!res.flag) {
                          return Promise.reject(res.message)
                        } else if (value < 0) {
                          return Promise.reject(`数量不小于零`)
                        }
                        return Promise.resolve()
                      },
                    },
                  ],
                },
                taxAmount: {
                  rules: [
                    {
                      validator: (_, value) => {
                        const res = checkBlue("vatAmount", "增值税", value)
                        if (!res.flag) {
                          return Promise.reject(res.message)
                        } else if (value < 0) {
                          return Promise.reject(`金额不小于零`)
                        }
                        return Promise.resolve()
                      },
                    },
                  ],
                },
                totalAmount: {
                  rules: [
                    {
                      validator: (_, value) => {
                        const res = checkBlue("totalAmount", "价税金额", value)
                        if (!res.flag) {
                          return Promise.reject(res.message)
                        } else if (value < 0) {
                          return Promise.reject(`价税金额不小于零`)
                        }
                        return Promise.resolve()
                      },
                    },
                  ],
                },
                amount: {
                  rules: [
                    {
                      validator: (_, value) => {
                        const res = checkBlue("totalPrice", "金额", value)
                        if (!res.flag) {
                          return Promise.reject(res.message)
                        } else if (value < 0) {
                          return Promise.reject(`金额不小于零`)
                        }
                        return Promise.resolve()
                      },
                    },
                  ],
                },
              }}
            ></InvoiceOfflineForm>
          </ModalForm>
        </Space>
      }
    >
      <div>
        <Row className="apply-detail-row">
          {context?.data?.invoiceStatus !== "PENDING" && (
            <Col span="8">
              <div className={"info_items"}>
                <span className={"label"}>申请开票时间:</span>
                <span className={"value"}>{context?.data?.applyInvoiceTime}</span>
              </div>
            </Col>
          )}
        </Row>
      </div>
      <Table scroll={{ x: "max-content" }} columns={columns} dataSource={dataSource}></Table>
      {isModalOpenApply && (
        <ProcureApplyBilling
          isModalOpen={isModalOpenApply}
          setIsModalOpen={setIsModalOpenApply}
          selectedRowKeys={[{ id: historyId }]}
          handleRefresh={async () => {
            await init()
            await context.refresh()
          }}
        />
      )}
    </Card>
  )
})
